.languages{
  background: #eee;
}

.footer a,
.footer a:hover,
.footer a:active {
  color: #fff;
  text-decoration: none;
}

a:hover, a:active, a:visited {
  text-decoration: none!important;
}

.slick-slider {
  padding: 0 25px;
  position: relative;
}

.slick-slider .myarr {
  position: absolute;
  font-size: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 99;
}

.slide-techs  .myarr.arr-right {
  right: 0;
}

.slide-techs  .myarr.arr-left {
  left: 0;
}

.slick-slider img {
  margin: 0 auto;
  width: auto;
  max-width: 150px;
  height: auto;
  max-height: 100px;
}

.slick-slide {
  background: none;
}

.containers-logos{
  background: #fff;
  box-sizing: border-box;
  border: 5px solid #007bff;
}

/* start animation component */
.page-enter{
    opacity: 0.01;
    transform: translateX(-100%)
}
.page-enter-active {
    opacity: 1;
    transform: translateX(0%);
    transition: all 300ms ease-in;
}

.page-exit {
    opacity: 1;
    transform: translateX(0%);
}

.page-exit-active {
    opacity: 0.01;
    transform: translateX(-100%);
    transition: all 300ms ease-out;
}

/* stop animation component */


@media only screen and (max-width: 767.98px) {
  div.container.navbar-contain {
    text-align: center;
    display: inline-block;
  }
  .header-nav.navbar-nav {
    text-align: center;
    display: inline-block;
  }
  .navbar-contain .navbar-left {
    display: inline-block;
    width: 100%;
  }
  .descript-icon {
    display: none;
  }

  .header-nav .fa{
    font-size: 2.5em;
  }
  #contact-map .leaflet-container {
    height: 200px;
  }

}

@media only screen and (min-width: 768px) {
  #contact-map .leaflet-container {
    height: 350px;
  }

  .big-contain{
    min-height: 300px;
  }


  .header-nav.navbar-nav {
    display: flex;
  }
  .descript-icon {
    display: inline-block;
  }

  .header-nav .fa{
    font-size: 1.8em;
  }
}

.weblogo {
  max-height: 50px;
}

.header-nav li {
  padding-left: 7px;
  display: inline-block;
}

.nav-link {
  text-align: center;
}

.language-menu {
  text-align: center;
}

.language-menu img {
  display: inline-block;
  max-width: 40px;
  padding: 3px;
  cursor: pointer;
}
.language-menu img.active {
  border-bottom: 2px solid #007bff;
}



@media (max-width: 575.98px) {   }

@media (min-width: 576px) and (max-width: 767.98px) { }

@media (min-width: 768px) and (max-width: 991.98px) {   }

@media (min-width: 992px) and (max-width: 1199.98px) {
  #contact-map .leaflet-container {
    height: 450px;
  }
 }

@media (min-width: 1200px) {   }
